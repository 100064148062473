import React from "react"

const SectionVideo = ({ section }) => {
  if (section.body.includes("youtube")) {
    const video = section.body.replace('youtube.com', 'youtube-nocookie.com')
    return (
      <div className="c-sectionchar">
        <div className="c-video">
          <iframe
            title="video"
            width="100%"
            height="100%"
            src={video}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    )
  } else return null
}

export default SectionVideo
